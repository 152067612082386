const size = {
    mobile: '576px',
    tablet: '768px',
    laptop: '992px',
    desktop: '1200px'
}

export const device = {
    mobile: `(min-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,

    lessThanDesktop: `(max-width: ${size.desktop})`
}


export const width = {
    sm: '540px',
    md: '750px',
    lg: '970px',
    xl: '1170px',
    xxl: '1900px'
}