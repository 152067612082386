export const white = '#ffffff';
export const lightGrey = '#eeeeee';
export const lightGreyBorder = 'rgba(255,255,255,.5)';
export const lightGreyLine = '#e0dcdc';
export const black = '#000000';
export const darkGrey = '#444';
export const mediumGrey = '#aaa';
export const magenta = '#EB058C';
export const magentaLight = '#ea309d';
export const darkMagenta = '#f70477';
export const pinkButton = '#eb054e';
export const blue = '#26a7bd';
export const lightBlue = '#c9e8ee';
export const darkBlue = '#007AFF';
export const greyTransparent = 'rgba(168,165,165, .5)';
export const lightGreen = '#0dd3c5';
export const textDarkGrey = '#444';
export const superDarkGrey = '#191a1b';
export const grey = '#26262b';
export const blueGrey = '#c0cfd8';
export const pinkGrey = '#ccb1be';
export const darkBlueGrey = '#1e2024';
export const purple = '#850dd0';



export const fontBold = '700';

export const fontSizeXs = '0.68rem';
export const fontSizeSm = '0.875rem';
export const fontSizeMd = '1rem';
export const fontSizeReg = '1.5rem';
export const fontSizeLg = '1.8rem';
export const fontSizeXl = '3rem';
export const fontSizeTitle = '4rem';
export const fontSizeXxl = '5rem';

export const fontWeightTitle = '500';
export const fontWeightText = '400';

export const letterSpacingSm = '1px';

export const lineHeightXs = '1.2rem';
export const lineHeightSm = '1.6rem';
export const lineHeightMd = '1.8rem';
export const lineHeightLg = '2.2rem';


export const headerHeight = '80px';
export const textShadowGrey = '0 0 10px rgba(0,0,0,.1)'
export const textShadowDarkGrey = '0 0 10px rgba(0,0,0,.7)'




